// Inspired by: https://github.com/mmistakes/minimal-mistakes/blob/master/_sass/minimal-mistakes/_syntax.scss

// ==========================================================================
//   Syntax highlighting
// ========================================================================== 

div.highlighter-rouge,
figure.highlight {
    position: relative;
    margin-bottom: 1em;
    background: $base00;
    color: $base05;
    font-family: $monospace;
    font-size: $font-size-code;
    line-height: 1.8;
    border-radius: $border-radius;
    > pre,
    pre.highlight {
        margin: 0;
        padding: 1em
    }
}

code.highlighter-rouge {
    padding: 0.2em 0.4em;
    font-size: $font-size-code;
    background-color: transparentize($base00, 0.85);
    color: rgb(36, 41, 46);
    border-radius: 2px;
    font-family: $monospace;
}

.highlight table {
    margin-bottom: 0;
    font-size: 1em;
    border: 0;
    td {
        padding: 0;
        width: calc(100% - 1em);
        border: 0;
        /* line numbers*/
        &.gutter,
        &.rouge-gutter {
            padding-right: 1em;
            width: 2em;
            color: $base04;
            border-right: 1px solid $base04;
            text-align: right
        }

        /* code */
        &.code,
        &.rouge-code {
            padding-left: 1em
        }
    }

    pre {
        margin: 0
    }
}

.highlight pre{ width:100%}
.highlight .hll{ background-color:$base06}

.highlight{ 
 .c{ /* Comment */ color:$base04}
 .err{ /* Error */ color:$base08}
 .k{ /* Keyword */ color:$base0e}
 .l{ /* Literal */ color:$base09}
 .n{ /* Name */ color:$base05}
 .o{ /* Operator */ color:$base0c}
 .p{ /* Punctuation */ color:$base05}
 .cm{ /* Comment.Multiline */ color:$base04}
 .cp{ /* Comment.Preproc */ color:$base04}
 .c1{ /* Comment.Single */ color:$base04}
 .cs{ /* Comment.Special */ color:$base04}
 .gd{ /* Generic.Deleted */ color:$base08}
 .ge{ /* Generic.Emph */ font-style:italic}
 .gh{ /* Generic.Heading */ color:$base05;  font-weight:bold}
 .gi{ /* Generic.Inserted */ color:$base0b}
 .gp{ /* Generic.Prompt */ color:$base04;  font-weight:bold}
 .gs{ /* Generic.Strong */ font-weight:bold}
 .gu{ /* Generic.Subheading */ color:$base0c;  font-weight:bold}
 .kc{ /* Keyword.Constant */ color:$base0e}
 .kd{ /* Keyword.Declaration */ color:$base0e}
 .kn{ /* Keyword.Namespace */ color:$base0c}
 .kp{ /* Keyword.Pseudo */ color:$base0e}
 .kr{ /* Keyword.Reserved */ color:$base0e}
 .kt{ /* Keyword.Type */ color:$base0a}
 .ld{ /* Literal.Date */ color:$base0b}
 .m{ /* Literal.Number */ color:$base09}
 .s{ /* Literal.String */ color:$base0b}
 .na{ /* Name.Attribute */ color:$base0d}
 .nb{ /* Name.Builtin */ color:$base05}
 .nc{ /* Name.Class */ color:$base0a}
 .no{ /* Name.Constant */ color:$base08}
 .nd{ /* Name.Decorator */ color:$base0c}
 .ni{ /* Name.Entity */ color:$base05}
 .ne{ /* Name.Exception */ color:$base08}
 .nf{ /* Name.Function */ color:$base0d}
 .nl{ /* Name.Label */ color:$base05}
 .nn{ /* Name.Namespace */ color:$base0a}
 .nx{ /* Name.Other */ color:$base0d}
 .py{ /* Name.Property */ color:$base05}
 .nt{ /* Name.Tag */ color:$base0c}
 .nv{ /* Name.Variable */ color:$base08}
 .ow{ /* Operator.Word */ color:$base0c}
 .w{ /* Text.Whitespace */ color:$base05}
 .mf{ /* Literal.Number.Float */ color:$base09}
 .mh{ /* Literal.Number.Hex */ color:$base09}
 .mi{ /* Literal.Number.Integer */ color:$base09}
 .mo{ /* Literal.Number.Oct */ color:$base09}
 .sb{ /* Literal.String.Backtick */ color:$base0b}
 .sc{ /* Literal.String.Char */ color:$base05}
 .sd{ /* Literal.String.Doc */ color:$base04}
 .s2{ /* Literal.String.Double */ color:$base0b}
 .se{ /* Literal.String.Escape */ color:$base09}
 .sh{ /* Literal.String.Heredoc */ color:$base0b}
 .si{ /* Literal.String.Interpol */ color:$base09}
 .sx{ /* Literal.String.Other */ color:$base0b}
 .sr{ /* Literal.String.Regex */ color:$base0b}
 .s1{ /* Literal.String.Single */ color:$base0b}
 .ss{ /* Literal.String.Symbol */ color:$base0b}
 .bp{ /* Name.Builtin.Pseudo */ color:$base05}
 .vc{ /* Name.Variable.Class */ color:$base08}
 .vg{ /* Name.Variable.Global */ color:$base08}
 .vi{ /* Name.Variable.Instance */ color:$base08}
 .il{ /* Literal.Number.Integer.Long */ color:$base09}
}

// Fix Github syntax display
.gist .blob-num {
    width: 3.5% !important;
}

// Fix nbconvert dataframe
.dataframe tbody {
    font-size: 0.8em;
    border-color: white;

    tr{
        :nth-child(even) {
            background-color: #f8f8f8;
        }

        th {
            border-top: 0;
            border-left: 0;
            border-bottom: 0;
        }


        td {
            padding: 0;
            border: 0;
            text-align: center;
        }
    }
    
    
}

.dataframe thead tr {
    :nth-child(n+2) {
        color: black;
        //width: 100px;
        
        font-size: 0.75em;
        text-align: center !important;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
    
    :first-child {
        border-top: 0;
        border-left: 0;
    }
    
}

table.dataframe {
    border: 0 solid $border-color;
    border-collapse: collapse;
}

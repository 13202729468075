.site-footer {
    @extend %padding-regular;
    display: inline-block;
    text-align: center;
    width: 100%;
    color: lighten($text-color, 24%);
    font-size: 0.9em;

    .footer-icons {
        ul {
            list-style: none;
            li {
                display: inline;
            }
            a:hover {
                color: darken($link-color, 15%);
            }
        }
    }
}

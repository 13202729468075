.tag-list {
    width: 100%;
    font-size: 0.9em;
    padding-bottom: $padding-x-small;

    div.meta {
        margin-right: 0.5em;
    }

    a.button {
        margin: 0.1em;
        padding: 0.2em 0.4em;
    }

    p {
        margin: 0;
        padding: 0 0.2em 0 0;
    }
}

.tag-anchor {
    margin-bottom: $padding-x-small;
    a {
        color: $link-color;

        &:hover {
            text-decoration: none;
        }
    }
}

.tag-posts {
    display: inline-block;
    width: 100%;
    .tag-title {
        margin-top: 0;
        margin-bottom: 0;
        a {
            margin-left: $padding-x-small;
        }
    }

    .meta {
        display: inline;
        float: right;
        font-size: 0.7em;
        line-height: normal;
    }
}

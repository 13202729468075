#post-nav {
    width: 100%;
    display: inline-block;
    @extend %padding-small;
   
    a {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    p {
        @extend .meta;
    }
    
    #previous-post {
        float: left;
    }
    
    #next-post {
        float: right;
    }
    
    @media screen and (max-width: $sm-break) {
    a, p {
        font-size: 0.8em;
    }
}
}
